
export const WebProVpn = {
    webWin: 'https://store.bittorrent.com/849/purl-utwebprovpnwebwinreason',
    webMac: 'https://store.bittorrent.com/849/purl-utwebprovpnwebmacreason',
}
export const WebPro = {
    webWin: 'https://store.bittorrent.com/849/purl-utwprowebwinreason',
    webMac: 'https://store.bittorrent.com/849/purl-utwebprowebmacreason',
}
export const WebAf = {
    webWin: 'https://store.bittorrent.com/849/purl-utwafwebwin',
    webMac: 'https://store.bittorrent.com/849/purl-utwafwebmac',
}

